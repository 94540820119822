<template>
  <div
    class="adventsraetsel openingAnimation"
    :class="animState"
    @click="changeAnimState(1)"
  >
    <div class="giftcard-area">
      <div class="giftcard">
        <img
          src="./assets/couponDesign.svg"
          alt="5€-Gutschein für den Q2-Kiosk"
        />
      </div>
    </div>
    <div class="giftbox-area">
      <div class="box">
        <img class="top" src="./assets/top.svg" alt="" />
        <img class="bottom" src="./assets/bottom.svg" alt="" />
        <img class="bottomBack" src="./assets/bottom-back.svg" alt="" />

        <img class="sparkles sparkles1" src="./assets/sparkles1.svg" alt="*" />
        <img class="sparkles sparkles2" src="./assets/sparkles2.svg" alt="*" />
      </div>
      <img class="shadow" src="./assets/shadow.svg" alt="" />
    </div>
    <div class="stage-area">
      <img class="stage" src="./assets/stage.svg" alt="Bühne" />
      <p class="hint">Zum Öffnen tippen</p>
    </div>
    <div class="background">
      <img src="./assets/background.svg" alt="" />
    </div>
    <v-btn
      fab
      color="primary"
      class="nextBtn"
      fixed
      elevation="4"
      large
      @click="changeAnimState(2)"
    >
      <v-icon>arrow_forward</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'Adventsraetsel',
  data() {
    return {
      animState: 'start',
    };
  },
  methods: {
    changeAnimState(stateNum) {
      if (stateNum == 1 && this.animState == 'start') {
        this.animState = 'present';
      } else if (stateNum == 2 && this.animState == 'present') {
        this.animState = 'transition';

        const giftcard = document.querySelector(
          '.adventsraetsel .giftcard-area'
        );
        const couponcard = document.querySelector(
          '.coupon-page .card-area .card'
        );

        couponcard.style.transform = 'none';

        setTimeout(() => {
          giftcard.style.top = couponcard.getBoundingClientRect().top + 'px';
          giftcard.style.left =
            couponcard.getBoundingClientRect().left +
            couponcard.offsetWidth / 2 +
            'px';

          couponcard.removeAttribute('style');
        });

        setTimeout(() => {
          this.animState = 'end';
          this.$emit('animation-end');
        }, 800);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.adventsraetsel {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #7ec07e;

  &.end {
    display: none;
  }

  &.start {
    cursor: pointer;
  }

  &.present {
    .giftcard-area {
      bottom: 50%;
      transform: translate(-50%, 50%) scale(1);
      opacity: 1;
    }

    .giftbox-area {
      .box {
        animation: none;

        .top {
          position: absolute;
          bottom: 55vh;
          left: 45vw;
          transform: translateX(-50%) rotate(30deg);
          z-index: 2;

          @media #{map-get($display-breakpoints, 'md-and-up')} {
            left: 250%;
          }
        }

        .sparkles {
          &.sparkles1 {
            transform: translateX(-50%) rotate(-10deg) scale(1);
          }

          &.sparkles2 {
            transform: translateX(-50%) rotate(10deg) scale(1);
          }
        }
      }
    }

    .stage-area {
      .hint {
        opacity: 0;
      }
    }

    .background {
      transform: translateX(-50%);
    }

    .nextBtn {
      transform: translateX(-50%);
    }
  }

  &.transition {
    .giftcard-area {
      // top: 126px;
      // bottom: auto;
      // left: auto;
      transform: translate(-50%, 0);
      transition: all 500ms cubic-bezier(0.42, 0, 0.25, 1);
      opacity: 1;

      .giftcard {
        transform: rotateX(90deg);
        transition: all 300ms ease-out 500ms;
      }
    }

    .giftbox-area {
      opacity: 0;
      transition: opacity 200ms ease-out;

      .box {
        animation: none;

        .top {
          position: absolute;
          bottom: 75vh;
          left: 80vw;
          transform: translateX(-50%) rotate(30deg);
          z-index: 2;
        }

        .sparkles {
          &.sparkles1 {
            transform: translateX(-50%) rotate(-10deg) scale(1);
          }

          &.sparkles2 {
            transform: translateX(-50%) rotate(10deg) scale(1);
          }
        }
      }
    }

    .stage-area {
      opacity: 0;
      transition: opacity 200ms ease-out;
    }

    .background {
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity 200ms ease-out;
    }

    .nextBtn {
      transform: translateX(-50%) scale(0);
      opacity: 0;
      transition: opacity 200ms ease-out;
    }
  }

  .giftcard-area {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0) scale(0.2);
    z-index: 2;
    width: calc(100% - 2 * 24px);
    max-width: 384px;
    transition: all 300ms ease-out;
    opacity: 0;

    transform-style: preserve-3d;
    perspective: 1000px;
    backface-visibility: hidden;

    .giftcard {
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 8px rgba(#000000, 0.26);
      backface-visibility: hidden;
      transform: rotateX(0deg);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .giftbox-area {
    position: absolute;
    bottom: 18%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    .shadow {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .box {
      position: relative;
      bottom: -5px;
      left: 51%;
      transform: translateX(-50%);
      z-index: 1;
      transform-origin: center center;
      animation: pop 2.4s infinite;

      @keyframes pop {
        0% {
          transform: translate(-50%, 0) scale(1, 1);
        }
        25% {
          transform: translate(-50%, -50%) scale(0.9, 1.1);
        }
        27.5% {
          transform: translate(-50%, -50%) rotate(5deg);
        }
        30% {
          transform: translate(-50%, -50%) rotate(-5deg);
        }
        32.5% {
          transform: translate(-50%, -50%) rotate(5deg);
        }
        35% {
          transform: translate(-50%, -50%) rotate(-5deg);
        }
        50% {
          transform: translate(-50%, 0) scale(1.1, 0.9);
        }
        60% {
          transform: translate(-50%, 0) scale(1, 1);
        }
        90% {
          transform: translate(-50%, 0) scale(1, 1);
        }
      }

      .bottom {
        position: relative;
        z-index: 1;
      }

      .bottomBack {
        position: absolute;
        bottom: 73.5%;
        left: 50%;
        transform: translateX(-50%);
      }

      .top {
        position: absolute;
        bottom: 75%;
        left: 49.5%;
        transform: translateX(-50%);
        z-index: 2;
        transition: all 200ms ease-out;
      }

      .sparkles {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
        transition: all 150ms ease-out;

        &.sparkles1 {
          bottom: 90%;
          left: 140%;
          width: 40vw;
          max-width: 160px;
          transform: translateX(-50%) rotate(-10deg) scale(0);
          transform-origin: left bottom;
        }

        &.sparkles2 {
          bottom: 80%;
          left: -30%;
          width: 45vw;
          max-width: 160px;
          transform: translateX(-50%) rotate(10deg) scale(0);
          transform-origin: right bottom;
        }
      }
    }
  }

  .stage-area {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translateX(-50%);

    .stage {
      width: 150vw;
      max-width: 800px;
    }

    .hint {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: rgba(#ffffff, 0.56);
    }
  }

  .background {
    position: absolute;
    bottom: 21%;
    left: 50%;
    width: 250%;
    transform: translateX(-50%) scale(0);
    transform-origin: center bottom;
    transition: all 500ms ease-out;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .nextBtn {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%) scale(0);
    z-index: 2;
    background-color: #e63f59 !important;
    transform-origin: center center;
    transition: all 200ms ease-in-out;
  }
}
</style>
