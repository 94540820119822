<template>
  <div class="coupon-page" :class="{ hide }">
    <Adventsraetsel class="temp" @animation-end="animationEnd" v-if="hide" />
    <div
      class="container"
      v-if="!error.active && coupon && secret && secret.length"
    >
      <div class="help-area">
        <p class="description">Wie du den Gutschein einlöst</p>
        <v-btn icon class="help-button" @click="helpDialog = true">
          <v-icon class="help-icon">help</v-icon>
        </v-btn>
      </div>
      <div class="card-area">
        <h2 class="heading">
          {{ coupon.name }}
          <v-btn
            class="downloadBtn"
            icon
            dark
            x-small
            @click="downloadDialog = true"
          >
            <v-icon class="icon">get_app</v-icon>
          </v-btn>
        </h2>
        <div class="card">
          <img class="logo" src="@/assets/fhgNewsLogo.svg" alt="FHG News" />
          <img
            class="qrcode"
            :src="
              `https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=${debitLink}`
            "
            alt="QR-Code"
          />
          <div class="information">
            <p class="name">{{ coupon.for }}</p>
            <p class="id">{{ secret[0].ID }}</p>
          </div>
        </div>
      </div>
      <div class="details-area">
        <div class="value-area">
          <div class="current">
            <p class="name">Aktueller Wert</p>
            <p class="value">
              {{ currentValue.toFixed(2).replace('.', ',') }} €
            </p>
          </div>
          <div class="initial">
            <p class="name">Ursprungswert</p>
            <p class="value">
              {{
                coupon.mode === 'value'
                  ? coupon.value.toFixed(2).replace('.', ',') + ' €'
                  : ''
              }}
            </p>
          </div>
        </div>
        <div class="debits-area">
          <p class="name">Abbuchungen</p>
          <div
            class="debits-list"
            :class="{ empty: !secret[0].debits || !secret[0].debits.length }"
          >
            <div
              class="nothing"
              v-if="!secret[0].debits || !secret[0].debits.length"
            >
              noch keine Abbuchungen
            </div>
            <div
              class="debit"
              v-for="(item, index) in secret[0].debits"
              :key="index"
            >
              <p class="date">{{ item.time | dateAndTime }}</p>
              <p class="value" :class="{ isPositive: item.value >= 0 }">
                {{
                  (item.value > 0 ? '+' : '') +
                    parseFloat(item.value)
                      .toFixed(2)
                      .replace('.', ',')
                }}
                €
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="newDebit.active"
      elevation="10"
      persistent
      max-width="500"
      content-class="newDebitDialog"
    >
      <v-card>
        <v-card-title
          style="word-break: break-word;"
          class="newDebitDialog__title"
        >
          <v-icon class="icon">check_circle</v-icon>
          <span>Neue Abbuchung erfolgreich</span>
        </v-card-title>
        <v-card-text>
          <div class="newDebitDialog__newDebit">
            <p class="date">{{ newDebit.time | dateAndTime }}</p>
            <p class="value" :class="{ isPositive: newDebit.value >= 0 }">
              {{
                (newDebit.value > 0 ? '+' : '') +
                  parseFloat(newDebit.value)
                    .toFixed(2)
                    .replace('.', ',')
              }}
              €
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="newDebit = { active: false, date: null, value: null }"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="error.active" persistent max-width="500">
      <v-card>
        <template v-if="error.code == 'deactive'">
          <v-card-title style="word-break: break-word;">
            Gutschein deaktiviert
          </v-card-title>
          <v-card-text>
            Der Gutschein, den du versuchst aufzurufen, wurde von einem
            Administrator deaktiviert. Falls es sich dabei um einen Fehler
            handelt, versuche es später erneut oder kontaktiere den Support.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="error.active = false" exact to="/">OK</v-btn>
          </v-card-actions>
        </template>
        <template v-if="error.code === 'notFound'">
          <v-card-title style="word-break: break-word;">
            Gutschein nicht gefunden
          </v-card-title>
          <v-card-text>
            Der Gutschein mit der angegebenen ID konnte nicht gefunden werden.
            Versuche es später erneut oder wende dich an den Support.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="error.active = false" exact to="/">OK</v-btn>
          </v-card-actions>
        </template>
        <template v-if="error.code === 'unknown'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Aufruf des Gutscheins
          </v-card-title>
          <v-card-text>
            Als wir versucht haben den Gutschein mit der angegebenen ID
            aufzurufen, ist ein unerwarteter Feher aufgetreten. Bitte versuche
            es später erneut oder wende dich an den Support.<br />
            Fehlercode: {{ error.catched }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="error.active = false" exact to="/">OK</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="downloadDialog"
      scrollable
      fullscreen
      persistent
      hide-overlay
      content-class="downloadDialog"
      transition="dialog-bottom-transition"
    >
      <div class="outer">
        <v-toolbar class="toolbar">
          <div class="container">
            <v-btn icon @click="downloadDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <h3 class="title">
              Offline-Nutzung
            </h3>
          </div>
        </v-toolbar>
        <div class="container">
          <h3 class="title mb-4">
            Wann sollte ich diese Funktion nutzen?
          </h3>
          <p class="body-1">
            Beim Einlösen der FHG News Gutscheine (beispielsweise in der Schule)
            solltest du optimaler Weise eine Internetverbindung beispielsweise
            per mobiler Daten haben. In dem Fall kannst du deinen Gutschein
            einfach über den von uns erhaltenen Link öffnen und die Abbuchung
            durchführen.
          </p>
          <p class="body-1">
            Die Offline-Funktion hingegen solltest du nur nutzen, wenn du beim
            Einlösen deines FHG News Gutscheins keine Internetverbindung
            besitzt, weil du beispielsweise kein Datenvolumen mehr übrig hast,
            du kein Smartphone besitzt oder dieses nicht dabei hast, wenn du
            deinen Gutschein einlösen willst.
          </p>
          <p class="body-1">
            Es ist zu beachten, dass bei der Offline-Nutzung deines Gutscheins
            eine höhere Gefahr besteht, dass nicht berechtigte Personen von
            deinem Gutschein gebrauch machen beispielsweise durch einen
            Hackerangriff oder Diebstahl. Für diesen Fall kann die Redaktion der
            FHG News keine Rückerstattung garantieren.
          </p>
          <h3 class="title mb-4">
            Offline-Nutzung aktivieren
          </h3>
          <div class="actionBtns">
            <v-card :ripple="true" class="actionBtn" @click="downloadSnapshot">
              <v-icon class="icon">image</v-icon>
              <h4 class="heading">Gutschein Snapshot herunterladen</h4>
              <p class="description">
                Wenn du beim Einlösen keine Internetverbindung besitzt
              </p>
            </v-card>
            <v-card :ripple="true" class="actionBtn" @click="printCoupon">
              <v-icon class="icon">print</v-icon>
              <h4 class="heading">Gutschein ausdrucken</h4>
              <p class="description">
                Wenn du beim Einlösen kein Smartphone dabei hast
              </p>
            </v-card>
          </div>
        </div>
      </div>
    </v-dialog>

    <v-dialog
      v-model="helpDialog"
      scrollable
      fullscreen
      persistent
      hide-overlay
      content-class="helpDialog"
      transition="dialog-bottom-transition"
    >
      <div class="outer">
        <v-toolbar class="toolbar">
          <div class="container">
            <v-btn icon @click="helpDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <h3 class="title">
              Hilfe
            </h3>
          </div>
        </v-toolbar>
        <div class="container">
          <h3 class="title mb-4">
            Wie kann ich meinen Gutschein einlösen?
          </h3>
          <p class="body-1">
            Um deinen Gutschein für den Q2-Kiosk einzulösen, kannst du in den
            Öffnungszeiten des Kiosks (u. a. jede 20-Minuten-Pause) zum Fenster
            des SV-Raums kommen und dort wie gewohnt einkaufen. Anstatt
            allerdings mit Bargeld zu zahlen, zeigst du den Mitarbeitern des
            Kiosks deinen Gutschein auf deinem Smartphone (oder falls du ihn zur
            Offline-Nutzung ausgedruckt hast die Papier-Version). Ein
            Mitarbeiter wird daraufhin den QR-Code, der dir angezeigt wird,
            abscannen und dir den entsprechenden Betrag von deinem Gutschein
            abbuchen. Wenn du mit dem Internet verbunden bist, sollte dir die
            Abbuchung auch direkt in einem Popup angezeigt werden und der
            aktuelle Wert deines Gutscheins sollte sich entsprechend ändern.
          </p>
          <h3 class="title mb-4">
            Was kann ich tun, wenn ich nicht mehr weiter weiß?
          </h3>
          <p class="body-1">
            Wenn du noch Fragen oder Probleme hast, kannst du dich gerne an uns
            wenden, indem du der Redaktion der FHG News eine
            <a class="link" href="mailto:redaktion@fhgnews.de"
              ><v-icon small>mail</v-icon> Mail schreibst</a
            >, einen Mitarbeiter des Q2-Kiosks fragst oder bei dringenden Fällen
            unseren
            <a class="link" href="tel:017643811166"
              ><v-icon small>phone</v-icon> technischen Support anrufst</a
            >.
          </p>
        </div>
      </div>
    </v-dialog>

    <div
      v-if="downloadSnapshotMode && coupon && secret && secret.length"
      class="snapshotContainer"
    >
      <div class="wrapper" ref="snapshotWrapper">
        <p class="downloaded">
          <v-icon class="icon">offline_pin</v-icon>
          Für Offline-Nutzng heruntergeladen am {{ new Date() | dateAndTime }}
        </p>
        <h2 class="heading">
          {{ coupon.name }}
        </h2>
        <div class="card">
          <img class="logo" src="@/assets/fhgNewsLogo.svg" alt="FHG News" />
          <img
            class="qrcode"
            :src="
              `https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=${debitLink}`
            "
            alt="QR-Code"
          />
          <div class="information">
            <p class="name">{{ coupon.for }}</p>
            <p class="id">{{ secret[0].ID }}</p>
          </div>
        </div>
        <div class="value-area">
          <p class="name">Ursprungswert</p>
          <p class="value">
            {{
              coupon.mode === 'value'
                ? coupon.value.toFixed(2).replace('.', ',') + ' €'
                : ''
            }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="downloadPrintMode && coupon && secret && secret.length"
      class="printContainer"
    >
      <div class="wrapper">
        <p class="heading">Gutschein zum Zusammenbasteln</p>
        <p class="description">
          Um den Gutschein richtig zu nutzen, schneide den Gutschein entlang der
          grauen Außenränder aus und knicke anschließend die beiden
          Karten-Seiten an der gestrichelten Linie, sodass der Gutschein eine
          Vorder- und Rückseite besitzt. Nachdem du die beiden Seiten an der
          unteren Kante geknickt hast, kannst du einen Klebestift oder -band
          nutzen, um die Vorder- und Rückseite aneinander zu kleben.
        </p>
        <div class="card">
          <img class="logo" src="@/assets/fhgNewsLogo.svg" alt="FHG News" />
          <img
            class="qrcode"
            :src="
              `https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=${debitLink}`
            "
            alt="QR-Code"
          />
          <div class="downloaded">
            <v-icon class="icon">offline_pin</v-icon>
            <p class="date">{{ new Date() | date }}</p>
          </div>
          <div class="information">
            <p class="name">{{ coupon.for }}</p>
            <p class="id">{{ secret[0].ID }}</p>
          </div>
        </div>
        <div class="line line-left"></div>
        <div class="line line-right"></div>
        <div class="couponDesign">
          <img
            src="@/components/templates/Adventsraetsel/assets/couponDesign.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Adventsraetsel from '@/components/templates/Adventsraetsel';
import { db, Timestamp } from '@/firebase';
import html2canvas from 'html2canvas';

export default {
  name: 'Coupon',
  components: {
    Adventsraetsel,
  },
  watch: {
    '$route.params.privatecouponid': {
      async handler(id) {
        if (id === 'd') {
          this.$router.replace({ path: '/d/' });
          return;
        }
        this.id = id;
        try {
          let secret = await this.$bind(
            'secret',
            db
              .collectionGroup('private')
              .where('ID', '==', id)
              .limit(1)
          );
          if (!secret.length) {
            this.error = { active: true, code: 'notFound' };
            return;
          }
          let pathParts = await db
            .collectionGroup('private')
            .where('ID', '==', id)
            .limit(1)
            .get()
            .then((documents) => {
              return documents.docs[0].ref.path;
            });
          this.secretPath = pathParts;
          pathParts = String(pathParts).split('/');
          if (pathParts.length < 6)
            return (this.error = {
              active: true,
              code: 'unknown',
              catched: 'PathTooShort',
            });
          let path = {
            [pathParts[0]]: pathParts[1],
            [pathParts[2]]: pathParts[3],
            [pathParts[4]]: pathParts[5],
          };
          let coupon = await this.$bind(
            'coupon',
            db.doc(`/campaigns/${path.campaigns}/coupons/${path.coupons}`)
          );

          if (!secret || !coupon) {
            this.error = { active: true, code: 'notFound' };
            return;
          }
          if (coupon && coupon.deactive === true) {
            this.error = { active: true, code: 'deactive' };
            this.$unbind('secret');
            this.$unbind('coupon');
            return;
          }
        } catch (e) {
          this.error = { active: true, code: 'unknown', catched: e };
        }
      },
      immediate: true,
    },
    couponSecret(secret, oldSecret) {
      if (!oldSecret || !secret || !secret.debits || !secret.debits.length)
        return;
      if (secret.debits.length == oldSecret.debits.length + 1) {
        secret.debits.forEach((debit) => {
          let found = false;
          for (let i = 0; i < oldSecret.debits.length; i++) {
            let oldDebit = oldSecret.debits[i];
            if (
              oldDebit.time.toDate().getMilliseconds() ==
                debit.time.toDate().getMilliseconds() &&
              oldDebit.value == debit.value
            ) {
              found = true;
              break;
            }
          }
          if (!found) {
            this.newDebit = {
              active: true,
              time: debit.time,
              value: debit.value,
            };
          }
        });
      }
    },
    '$route.query.print': {
      handler(print) {
        this.downloadPrintMode = !!print;
        if (print) {
          setTimeout(() => window.print(), 1500);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      id: null,
      secret: null,
      coupon: null,
      error: {
        active: false,
        code: '',
        catched: null,
      },
      newDebit: {
        active: false,
        time: null,
        value: null,
      },
      helpDialog: false,
      downloadDialog: false,
      downloadSnapshotMode: false,
      downloadPrintMode: false,
    };
  },
  filters: {
    dateAndTime(date) {
      if (date instanceof Timestamp) date = date.toDate();
      date = new Date(date);
      if (isNaN(date.getMilliseconds())) return;

      let formatter = Intl.DateTimeFormat('de-DE', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
      return formatter.format(date) + ' Uhr';
    },
    date(date) {
      if (date instanceof Timestamp) date = date.toDate();
      date = new Date(date);
      if (isNaN(date.getMilliseconds())) return;

      let formatter = Intl.DateTimeFormat('de-DE', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });
      return formatter.format(date);
    },
  },
  computed: {
    currentValue() {
      if (!this.coupon || this.coupon.mode !== 'value') return 0;

      let value = this.coupon.value;

      if (
        !this.secret[0] ||
        !this.secret[0].debits ||
        !this.secret[0].debits.length
      )
        return parseFloat(value) || 0.0;

      let totalDebit = 0.0;
      this.secret[0].debits.forEach((debit) => {
        totalDebit += debit.value;
      });

      return parseFloat(value) + parseFloat(totalDebit);
    },
    couponSecret() {
      if (!this.secret || !this.secret.length) return;
      return this.secret[0];
    },
    debitLink() {
      if (!this.couponSecret || !this.couponSecret.ID) return;
      return `${window.location.origin}/d/${this.couponSecret.ID}`;
    },
    hide() {
      if (
        this.error.active ||
        !this.coupon ||
        !this.secret ||
        !this.secret.length
      )
        return false;
      return !this.coupon.opened;
    },
  },
  methods: {
    animationEnd() {
      this.$firestoreRefs.coupon.update({ opened: true });
    },
    printCoupon() {
      let routeData = this.$router.resolve({
        path: this.$route.path,
        query: { print: true },
      });
      window.open(routeData.href, '_blank');
    },
    async downloadSnapshot() {
      this.downloadSnapshotMode = true;

      await new Promise((r) => setTimeout(r, 100));

      let snapshotWrapper = this.$refs.snapshotWrapper;
      if (!snapshotWrapper) {
        this.error = {
          active: true,
          code: 'unknown',
          catched: 'Snapshot konnte nicht generiert werden',
        };
        return;
      }

      this.downloadDialog = false;

      html2canvas(snapshotWrapper, { useCORS: true }).then((canvas) => {
        // Export the canvas to its data URI representation
        canvas.toBlob(async (blob) => {
          let sign = Math.floor(100000 + Math.random() * 900000);
          // Generate file download
          saveAs(blob, `gutscheinSnapshot${sign}.png`);
          this.downloadSnapshotMode = false;

          function saveAs(blob, fileName) {
            var url = window.URL.createObjectURL(blob);

            var anchorElem = document.createElement('a');
            anchorElem.style = 'display: none';
            anchorElem.href = url;
            anchorElem.download = fileName;

            document.body.appendChild(anchorElem);
            anchorElem.click();

            document.body.removeChild(anchorElem);

            // On Edge, revokeObjectURL should be called only after
            // a.click() has completed, atleast on EdgeHTML 15.15048
            setTimeout(function() {
              window.URL.revokeObjectURL(url);
            }, 100);
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.newDebitDialog {
  .newDebitDialog__title {
    text-align: center;
    width: 100%;

    .icon {
      display: block;
      margin: 0 auto 12px auto;
      color: #428ecc;
      font-size: 64px;
    }

    span {
      text-align: center;
      width: 100%;
    }
  }

  .newDebitDialog__newDebit {
    padding: 0 8px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .value {
      font-weight: 500;
      color: #bb2528;

      &.isPositive {
        color: #105f4f;
      }
    }
  }
}

.coupon-page {
  background-color: #7ec07e;
  padding: 0 24px;
  font-size: 14px;
  color: #ffffff;
  min-height: 100vh;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }

  .temp {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  &.hide {
    .help-area,
    .details-area {
      opacity: 0;
    }

    .card-area {
      .heading {
        opacity: 0;
      }

      .card {
        transform-style: preserve-3d;
        perspective: 1000px;
        backface-visibility: hidden;
        transform: rotateX(-90deg);
        transition: all 0ms ease;
      }
    }
  }

  .help-area,
  .details-area {
    transition: all 200ms ease 500ms;
  }

  .card-area {
    perspective: 1000px;

    .heading {
      transition: all 200ms ease 500ms;
    }

    .card {
      transition: all 300ms ease;
    }
  }

  .snapshotContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fafafa;
    z-index: 1000;
    color: #000000;

    .wrapper {
      width: 100%;
      max-width: 400px;
      padding: 24px;

      p {
        margin: 0;
      }

      .downloaded {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 14px;
        color: rgba(#000000, 0.56);
        margin: 0 0 16px 0;

        .icon {
          margin-right: 8px;
        }
      }

      .heading {
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        margin: 0 0 12px 0;
      }

      .card {
        position: relative;
        background-color: #ffffff;
        color: #000000;
        border: 1px solid rgba(#000000, 0.2);
        border-radius: 16px;
        overflow: hidden;

        &::before {
          content: '';
          width: 1px;
          margin-left: -1px;
          float: left;
          height: 0;
          padding-top: 63%;
        }

        &::after {
          content: '';
          display: table;
          clear: both;
        }

        .logo {
          margin: 12px 0 0 16px;
          width: 18%;
        }

        .qrcode {
          display: block;
          margin: 0 auto;
          position: absolute;
          top: 24px;
          left: 50%;
          transform: translateX(-50%);
          height: calc(90% - 24px - 32px);
        }

        .information {
          position: absolute;
          bottom: 0;
          left: 0;
          margin: 12px 0 12px 16px;
          line-height: 1;
          font-size: 14px;

          .name {
            font-weight: 500;
          }

          .id {
            font-weight: 400;
            color: rgba(#000000, 0.56);
          }
        }
      }

      .value-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 16px 0 16px;

        .name {
          font-size: 14px;
          font-weight: 400;
        }

        .value {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .printContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 1000;
    color: #000000;

    .wrapper {
      width: 190mm;
      height: 178mm;
      box-sizing: border-box;

      p {
        margin: 0;
      }

      .heading {
        font-size: 14pt;
        font-weight: 600;
        color: #000000;
        margin-bottom: 8pt;
      }

      .description {
        font-size: 12pt;
        font-weight: 400;
        color: #000000;
        margin-bottom: 20mm;
      }

      .card {
        position: relative;
        background-color: #ffffff;
        color: #000000;
        border: 1px solid rgba(#000000, 0.2);
        border-radius: 3.18mm;
        width: 85.6mm;
        height: 53.98mm;
        overflow: hidden;
        margin-left: 20mm;

        // &::before {
        //   content: '';
        //   width: 1px;
        //   margin-left: -1px;
        //   float: left;
        //   height: 0;
        //   padding-top: 63%;
        // }

        // &::after {
        //   content: '';
        //   display: table;
        //   clear: both;
        // }

        .logo {
          margin: 12px 0 0 16px;
          width: 18%;
        }

        .qrcode {
          display: block;
          margin: 0 auto;
          position: absolute;
          top: 24px;
          left: 50%;
          transform: translateX(-50%);
          height: calc(90% - 24px - 32px);
        }

        .downloaded {
          position: absolute;
          top: 12px;
          right: 16px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          font-weight: 14px;
          color: rgba(#000000, 0.56);
          margin: 0 0 16px 0;

          .icon {
            margin-bottom: 4px;
          }
        }

        .information {
          position: absolute;
          bottom: 0;
          left: 0;
          margin: 12px 0 12px 16px;
          line-height: 1;
          font-size: 14px;

          .name {
            font-weight: 500;
          }

          .id {
            font-weight: 400;
            color: rgba(#000000, 0.56);
          }
        }
      }

      .line {
        display: block;
        position: relative;
        height: 1px;
        width: 18mm;
        border-bottom: 1px dashed rgba(#000000, 0.56);
        margin: 0;
        padding: 0;
        margin-bottom: -1px;

        &.line-right {
          left: 107.6mm;
        }
      }

      .couponDesign {
        background-color: #ffffff;
        color: #000000;
        border: 1px solid rgba(#000000, 0.2);
        border-radius: 3.18mm;
        width: 85.6mm;
        height: 53.98mm;
        overflow: hidden;
        transform: rotate(180deg);
        margin-left: 20mm;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .container {
    padding: 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    max-width: 800px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin: 24px auto;
      display: grid;
      grid-template: [row1-start] 'lefttop .' auto [row1-end] [row2-start] 'left right' auto [row2-start] / 50% 50%;
      column-gap: 32px;
    }

    p {
      margin: 0;
    }
  }

  .help-area {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
    grid-area: lefttop;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex-direction: row-reverse;
      margin-top: 0;
    }

    .description {
      color: rgba(#ffffff, 0.56);
    }

    .help-button {
      margin-left: 8px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin-left: 0;
        margin-right: 8px;
      }

      .help-icon {
        color: #ffffff;
      }
    }
  }

  .card-area {
    margin: 32px 0 0 0;
    grid-area: left;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin: 12px 8px 0 8px;
    }

    .heading {
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      margin: 0 0 12px 0;

      .downloadBtn {
        margin-left: 4px;

        .icon {
          opacity: 0.56;
        }
      }
    }

    .card {
      position: relative;
      background-color: #ffffff;
      color: #000000;
      border-radius: 16px;
      overflow: hidden;
      // min-height: 196px;

      &::before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 63%;
      }

      &::after {
        content: '';
        display: table;
        clear: both;
      }

      .logo {
        margin: 12px 0 0 16px;
        // height: 40px;
        width: 18%;
      }

      .qrcode {
        display: block;
        margin: 0 auto;
        position: absolute;
        top: 24px;
        left: 50%;
        transform: translateX(-50%);
        height: calc(90% - 24px - 32px);
      }

      .information {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 12px 0 12px 16px;
        line-height: 1;
        font-size: 14px;

        .name {
          font-weight: 500;
        }

        .id {
          font-weight: 400;
          color: rgba(#000000, 0.56);
        }
      }
    }
  }

  .details-area {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    grid-area: right;

    .value-area {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      &:after {
        content: '';
        display: block;
        margin: 0 8px;
        border-bottom: 1px solid rgba(#ffffff, 0.2);
      }

      & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;

        &.current {
          .value {
            font-size: 18px;
            font-weight: 700;
          }
        }

        &.initial {
          margin: 4px 0 16px 0;
          color: rgba(#ffffff, 0.56);
        }
      }
    }

    .debits-area {
      margin: 12px 0 0 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .name {
        padding: 0 8px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .debits-list {
        margin: 24px 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        flex-grow: 1;

        &.empty {
          margin: 32px 0 0 0;
          justify-content: center;
          align-items: center;

          .nothing {
            color: rgba(#ffffff, 0.56);
          }
        }

        .debit {
          padding: 0 8px;
          margin: 0 0 16px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .value {
            font-weight: 500;
            color: #bb2528;

            &.isPositive {
              color: #105f4f;
            }
          }
        }
      }
    }
  }
}

.downloadDialog {
  .outer {
    display: flex;
    flex-flow: column;
    background-color: #fafafa;
    height: 100%;

    .toolbar {
      flex-grow: 0;
      .container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
        padding: 0;
        max-width: 800px;

        .title {
          margin-left: 16px;
        }
      }
    }

    .container {
      flex-grow: 1;
      display: block;
      margin: 0 auto;
      padding: 24px 16px;
      max-width: 800px;

      overflow: auto;

      .actionBtns {
        @media #{map-get($display-breakpoints, 'md-and-up')} {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }

        .actionBtn {
          box-shadow: none;
          border: 1px solid rgba(#000000, 0.2);
          border-radius: 8px;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 24px 0;
          padding: 16px;
          user-select: none;
          cursor: pointer;

          @media #{map-get($display-breakpoints, 'md-and-up')} {
            margin: 0 0 24px 0;

            &:first-child {
              margin-right: 24px;
            }
          }

          .icon {
            display: block;
            margin: 0 auto;
            font-size: 48px;
            width: 48px;
            height: 48px;
          }

          .heading {
            display: block;
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            text-align: center;
            margin: 8px 0;
          }

          .description {
            display: block;
            font-size: 14px;
            font-weight: 400;
            color: rgba(#000000, 0.56);
            text-align: center;
            margin: 0 24px;
          }
        }
      }
    }
  }
}

.helpDialog {
  .outer {
    display: flex;
    flex-flow: column;
    background-color: #fafafa;
    height: 100%;

    .toolbar {
      flex-grow: 0;
      .container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
        padding: 0;
        max-width: 800px;

        .title {
          margin-left: 16px;
        }
      }
    }

    .container {
      flex-grow: 1;
      display: block;
      margin: 0 auto;
      padding: 24px 16px;
      max-width: 800px;

      overflow: auto;

      .link {
        text-decoration: none;
        color: #1976d2;
      }
    }
  }
}
</style>
